'use client';

import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@howl/shared-utils/classnames/cn';

const buttonVariants = cva(
  'inline-flex leading-6 items-center justify-center rounded-full ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none font-medium',
  {
    variants: {
      variant: {
        primary:
          'bg-interaction-primary hover:bg-interaction-primary-hover focus:bg-interaction-primary-pressed disabled:bg-neutral-opacity-50 text-primary-dark text-center relative disabled:text-text-disabled',
        secondary:
          'bg-interaction-secondary hover:bg-interaction-secondary-hover focus:bg-interaction-secondary-pressed disabled:bg-neutral-opacity-50 text-primary-brand-dark fill-primary-brand-dark disabled:text-text-disabled',
        'secondary-overlay':
          'bg-interaction-secondary-overlay hover:bg-interaction-secondary-overlay-hover focus:bg-interaction-secondary-overlay-pressed disabled:bg-neutral-opacity-50 text-primary-brand-dark fill-primary-brand-dark disabled:text-text-disabled',
        tertiary:
          'bg-interaction-tertiary hover:bg-interaction-tertiary-hover focus:bg-interaction-tertiary-pressed disabled:text-text-disabled  text-primary-brand-dark fill-primary-brand-dark',
        critical:
          'bg-interaction-error hover:bg-interaction-error-hover focus:bg-interaction-error-pressed disabled:bg-neutral-opacity-50 disabled:text-text-disabled text-primary-dark text-center relative',
        'critical-secondary':
          'bg-interaction-tertiary hover:bg--tertiary-hover focus:bg--tertiary-pressed disabled:bg-neutral-opacity-50 disabled:text-text-disabled text-text-error',
        'dark-primary':
          'bg-dark hover:bg-dark-hover focus:bg-dark-pressed disabled:bg-neutral-opacity-50 text-primary-brand text-center relative disabled:text-text-disabled',
      },
      size: {
        small: 'px-5 py-4 rounded-full text-sm max-h-10',
        medium: 'px-6 py-4 rounded-full text-sm max-h-12',
        large: 'px-6 py-4 rounded-full text-base',
        xl: 'px-20 py-8 rounded-full text-lg',
        icon: 'px-3 p-3 flex items-center justify-center rounded-full',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'small',
    },
  },
);

interface ButtonPropsExtended extends VariantProps<typeof buttonVariants> {
  /** Indicate loading state */
  loading?: boolean;
}

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    ButtonPropsExtended {
  asChild?: boolean;
}

const Spinner = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <path d="M21 12a9 9 0 1 1-6.219-8.56" />
  </svg>
);

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      children,
      loading,
      disabled: buttonDisabled,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';
    const disabled = buttonDisabled || loading;
    if (asChild) {
      return (
        <Comp
          className={cn(buttonVariants({ variant, size, className }))}
          {...props}
          ref={ref}
          disabled={disabled}
        >
          {children}
        </Comp>
      );
    } else {
      return (
        <Comp
          className={cn(buttonVariants({ variant, size, className }))}
          ref={ref}
          disabled={disabled}
          {...props}
        >
          <>
            {loading && !asChild ? (
              <Spinner className="h-4 w-4 animate-spin" />
            ) : (
              <>{children}</>
            )}
          </>
        </Comp>
      );
    }
  },
);
Button.displayName = 'Button';

export { Button, buttonVariants };
