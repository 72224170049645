import { SVGProps } from 'react';

import React, { forwardRef } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: string | number;
  isFill?: boolean;
}

export const WarningIcon = forwardRef(
  (
    { color = 'currentColor', size = 24, isFill = false, ...rest }: Props,
    ref: any,
  ) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
      >
        <mask
          id="mask0_6366_1175"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill={color} />
        </mask>
        <g mask="url(#mask0_6366_1175)">
          <path
            d="M18.747 19.5H5.58699C5.00699 19.5 4.64699 18.87 4.93699 18.37L11.517 7.09C11.807 6.59 12.527 6.59 12.817 7.09L19.397 18.37C19.687 18.87 19.327 19.5 18.747 19.5ZM12.167 3L1.66699 21H22.667L12.167 3ZM12.917 9.75H11.417V15H12.917V9.75ZM12.917 16.5H11.417V18H12.917V16.5Z"
            fill={color}
          />
        </g>
      </svg>
    );
  },
);

WarningIcon.displayName = 'WarningIcon';
