import { SVGProps } from 'react';

import React, { forwardRef } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: string | number;
  isFill?: boolean;
}

export const DownloadIcon = forwardRef(
  (
    { color = 'currentColor', size = 24, isFill = false, ...rest }: Props,
    ref: any,
  ) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill={color}
      >
        <mask
          id="mask0_1107_102864"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill={color} />
        </mask>
        <g mask="url(#mask0_1107_102864)">
          <path
            d="M5.5035 13.15L6.5635 12.08L9.5535 15.07C10.0235 15.54 10.8335 15.21 10.8335 14.54V4H12.3435V14.53C12.3435 15.2 13.1535 15.53 13.6235 15.06L16.6035 12.08L17.6735 13.15L14.0735 16.75H9.1035L5.5035 13.15ZM18.3335 16C18.3335 17.65 16.9935 18.99 15.3435 18.99H7.8335C6.1835 18.99 4.8435 17.65 4.8435 16H3.3335V20.5H19.8435V16H18.3335Z"
            fill={color}
          />
        </g>
      </svg>
    );
  },
);

DownloadIcon.displayName = 'DownloadIcon';
