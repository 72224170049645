import { SVGProps } from 'react';

import { forwardRef } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: string | number;
  isFill?: boolean;
}

export const ErrorIcon = forwardRef(
  (
    { color = 'currentColor', size = 24, isFill = false, ...rest }: Props,
    ref: any,
  ) => {
    return isFill ? (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size ?? '48'}
        height={size ?? '48'}
        viewBox="0 0 48 48"
        fill="none"
      >
        <mask
          id="mask0_1229_121921"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="48"
          height="48"
        >
          <rect width="48" height="48" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_1229_121921)">
          <path
            d="M5.3335 24C5.3335 33.94 13.3935 42.02 23.3335 42.02C33.2735 42.02 41.3335 33.96 41.3335 24.02C41.3335 14.08 33.2735 6 23.3335 6C13.3935 6 5.3335 14.06 5.3335 24ZM24.8535 34.5H21.8535V31.5H24.8535V34.5ZM24.8535 28.5H21.8535V13.5H24.8535V28.5Z"
            fill={color}
          />
        </g>
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 25 24"
        fill={color}
      >
        <mask
          id="mask0_6366_1210"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="25"
          height="24"
        >
          <rect x="0.333496" width="24" height="24" fill={color} />
        </mask>
        <g mask="url(#mask0_6366_1210)">
          <path
            d="M3.00024 12C3.00024 16.97 7.03024 21 12.0002 21C16.9702 21 21.0002 16.97 21.0002 12C21.0002 7.03 16.9702 3 12.0002 3C7.03024 3 3.00024 7.03 3.00024 12ZM12.0102 19.5C7.87024 19.5 4.51024 16.14 4.51024 12C4.51024 7.86 7.87024 4.5 12.0102 4.5C16.1502 4.5 19.5102 7.86 19.5102 12C19.5102 16.14 16.1502 19.5 12.0102 19.5ZM12.7602 14.25H11.2602V6.75H12.7602V14.25ZM12.7602 17.25H11.2602V15.75H12.7602V17.25Z"
            fill={color}
          />
        </g>
      </svg>
    );
  },
);

ErrorIcon.displayName = 'ErrorIcon';
