import React, { forwardRef } from 'react';
import { SVGProps } from 'react';

interface Props extends SVGProps<SVGSVGElement> {
  color?: string;
  size?: string | number;
}

export const CloseIcon = forwardRef(
  ({ color = 'currentColor', size = 24, ...rest }: Props, ref: any) => {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width={size}
        height={size}
        viewBox="0 0 24 25"
        {...rest}
      >
        <mask
          id="mask0_928_61251"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="25"
        >
          <rect y="0.5" width="24" height="24" fill={color} />
        </mask>
        <g mask="url(#mask0_928_61251)">
          <path
            d="M18.3699 7.19988L17.2999 6.12988L13.0599 10.3699C12.4699 10.9599 11.5199 10.9599 10.9399 10.3699L6.69988 6.12988L5.62988 7.19988L9.86988 11.4399C10.4599 12.0299 10.4599 12.9799 9.86988 13.5599L5.62988 17.7999L6.69988 18.8699L10.9399 14.6299C11.5299 14.0399 12.4799 14.0399 13.0599 14.6299L17.2999 18.8699L18.3699 17.7999L14.1299 13.5599C13.5399 12.9699 13.5399 12.0199 14.1299 11.4399L18.3699 7.19988Z"
            fill={color}
          />
        </g>
      </svg>
    );
  },
);

CloseIcon.displayName = 'CloseIcon';
